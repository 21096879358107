import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  TestIdPartial,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/rn-card-group.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        disableCodeEditing
        code={snippet}
        platform="react-native"
        gitHubLink="modules/card-group"
        layout="rn"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['ReactNode']} isRequired>
            <Text>
              The card group accepts data card components as children. Data
              cards will be organized within the card group based on their{' '}
              <code>isEmpty</code> and <code>isHidden</code> props.
            </Text>
          </PropListItem>

          <PropListItem isRequired name="defaultCollapsed" types={['boolean']}>
            <Text>
              Determines whether the card group is collapsed by default.
            </Text>
            <List type="unordered">
              <li>
                <code>false</code> (default)
              </li>
            </List>
          </PropListItem>

          <PropListItem name="showHidden" types={['boolean']}>
            <Text>
              Determines whether to show the hidden data cards by default.
            </Text>
            <List type="unordered">
              <li>
                <code>false</code> (default)
              </li>
            </List>
          </PropListItem>

          <PropListItem name="headerControls" types={['ReactNode']}>
            <Text>
              An element displayed next to the title within the card group
              header.
            </Text>
          </PropListItem>

          <PropListItem name="headerTitle" types={['string']}>
            <Text>Text to display in the card group header.</Text>
          </PropListItem>

          <PropListItem name="id" types={['string']}>
            <Text>
              A unique identifier for card group elements in a Module.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <TestIdPartial componentName="card group" />
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Modules / Card Group"
      />
    </PlatformTab>
  );
};

export default ReactNativeTab;
