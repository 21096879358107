import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const CardGroup = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.CardGroup;
    return <Component {...props} />;
  },
});

const DataCard = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.DataCard;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/card-group.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, CardGroup: CardGroup, DataCard: DataCard }}
        code={snippet}
        platform="react"
        gitHubLink="modules/card-group"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['ReactNode']} isRequired>
            <Text>
              The card group accepts data card components as children. Data
              cards will be organized within the card group based on their{' '}
              <code>isEmpty</code> and <code>isHidden</code> props.
            </Text>
          </PropListItem>

          <ClassnamePartial componentName="card group" />

          <PropListItem isRequired name="defaultCollapsed" types={['boolean']}>
            <Text>
              Determines whether the card group is collapsed by default.
            </Text>
            <List type="unordered">
              <li>
                <code>false</code> (default)
              </li>
            </List>
          </PropListItem>

          <EnvironmentPartial />

          <PropListItem name="headerControls" types={['ReactNode']}>
            <Text>
              An element displayed next to the title within the card group
              header.
            </Text>
          </PropListItem>

          <PropListItem name="headerTitle" types={['string']}>
            <Text>Text to display in the card group header.</Text>
          </PropListItem>

          <PropListItem
            name="iconOrientation"
            types={["'vertical' | 'horizontal'"]}>
            <Text>Orientation of the expand/collapse icons.</Text>
            <List>
              <li>
                <code>'vertical'</code> (default)
              </li>
              <li>
                <code>'horizontal'</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="showHidden" types={['boolean']}>
            <Text>
              Determines whether to show the hidden data cards by default.
            </Text>
            <List type="unordered">
              <li>
                <code>false</code> (default)
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem
            name="onCollapseExpand"
            types={['(isCollapsed: boolean) => boolean']}>
            <Text>
              Called when card group is collapsed or expanded. Returning false
              can be used to prevent the card group from collapsing/expanding.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <PropListItem
            name="expandButtonRef"
            types={['React.Ref<HTMLButtonElement>']}>
            <Text>
              Ref set on the view more/less <code>&lt;button /&gt;</code>.
            </Text>
          </PropListItem>

          <PropListItem name="headerRef" types={['React.Ref<HTMLDivElement>']}>
            <Text>
              Ref set on the header <code>&lt;div /&gt;</code>.
            </Text>
          </PropListItem>

          <PropListItem name="innerRef" types={['React.Ref<HTMLDivElement>']}>
            <Text>
              Ref set on the inner <code>&lt;div /&gt;</code>.
            </Text>
          </PropListItem>

          <QaIdPartial componentName="card group" />
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Modules / Card Group"
      />
    </PlatformTab>
  );
};

export default WebTab;
